import * as Browser from 'bowser';
import * as heir from 'heir';
import * as Cookie from 'js-cookie';
import * as log from 'loglevel';
import * as objectAssign from 'object-assign';
import * as EventEmitter from 'wolfy87-eventemitter';

import Environment from './Environment';
import AlreadySubscribedError from './errors/AlreadySubscribedError';
import { InvalidArgumentError, InvalidArgumentReason } from './errors/InvalidArgumentError';
import { InvalidStateError, InvalidStateReason } from './errors/InvalidStateError';
import { NotSubscribedError, NotSubscribedReason } from './errors/NotSubscribedError';
import PermissionMessageDismissedError from './errors/PermissionMessageDismissedError';
import PushPermissionNotGrantedError from './errors/PushPermissionNotGrantedError';
import { PushPermissionNotGrantedErrorReason } from './errors/PushPermissionNotGrantedError';
import { SdkInitError, SdkInitErrorKind } from './errors/SdkInitError';
import Event from './Event';
import EventHelper from './helpers/EventHelper';
import HttpHelper from './helpers/HttpHelper';
import InitHelper from './helpers/InitHelper';
import MainHelper from './helpers/MainHelper';
import SubscriptionHelper from './helpers/SubscriptionHelper';
import TestHelper from './helpers/TestHelper';
import LimitStore from './LimitStore';
import AltOriginManager from './managers/AltOriginManager';
import LegacyManager from './managers/LegacyManager';
import SdkEnvironment from './managers/SdkEnvironment';
import { ServiceWorkerActiveState, ServiceWorkerManager } from './managers/ServiceWorkerManager';
import { SubscriptionManager } from './managers/SubscriptionManager';
import { AppConfig } from './models/AppConfig';
import Context from './models/Context';
import { Notification } from './models/Notification';
import { NotificationActionButton } from './models/NotificationActionButton';
import { NotificationPermission } from './models/NotificationPermission';
import { PermissionPromptType } from './models/PermissionPromptType';
import { Uuid } from './models/Uuid';
import { WindowEnvironmentKind } from './models/WindowEnvironmentKind';
import ProxyFrame from './modules/frames/ProxyFrame';
import ProxyFrameHost from './modules/frames/ProxyFrameHost';
import SubscriptionModal from './modules/frames/SubscriptionModal';
import SubscriptionModalHost from './modules/frames/SubscriptionModalHost';
import SubscriptionPopup from './modules/frames/SubscriptionPopup';
import SubscriptionPopupHost from './modules/frames/SubscriptionPopupHost';
import OneSignalApi from './OneSignalApi';
import Popover from './popover/Popover';
import Crypto from './services/Crypto';
import Database from './services/Database';
import { ResourceLoadState } from './services/DynamicResourceLoader';
import IndexedDb from './services/IndexedDb';
import {
  awaitOneSignalInitAndSupported,
  awaitSdkEvent,
  executeCallback,
  getConsoleStyle,
  isValidEmail,
  logMethodCall,
  prepareEmailForHashing,
} from './utils';
import { ValidatorUtils } from './utils/ValidatorUtils';
import { PushRegistration } from './models/PushRegistration';


export default class OneSignal {

  /**
   * Pass in the full URL of the default page you want to open when a notification is clicked.
   * @PublicApi
   */
  static async setDefaultNotificationUrl(url: string) {
    if (!ValidatorUtils.isValidUrl(url, { allowNull: true }))
      throw new InvalidArgumentError('url', InvalidArgumentReason.Malformed);
    await awaitOneSignalInitAndSupported();
    logMethodCall('setDefaultNotificationUrl', url);
    const appState = await Database.getAppState();
    appState.defaultNotificationUrl = url;
    await Database.setAppState(appState);
  }

  /**
   * Sets the default title to display on notifications. Will default to the page's document.title if you don't call this.
   * @remarks Either DB value defaultTitle or pageTitle is used when showing a notification title.
   * @PublicApi
   */
  static async setDefaultTitle(title: string) {
    await awaitOneSignalInitAndSupported();
    logMethodCall('setDefaultTitle', title);
    const appState = await Database.getAppState();
    appState.defaultNotificationTitle = title;
    await Database.setAppState(appState);
  }

  /**
   * Hashes the provided email and uploads to OneSignal.
   * @remarks The email is voluntarily provided.
   * @PublicApi
   */
  static async syncHashedEmail(email) {
    if (!email)
      throw new InvalidArgumentError('email', InvalidArgumentReason.Empty);
    let sanitizedEmail = prepareEmailForHashing(email);
    if (!isValidEmail(sanitizedEmail))
      throw new InvalidArgumentError('email', InvalidArgumentReason.Malformed);
    await awaitOneSignalInitAndSupported();
    logMethodCall('syncHashedEmail', email);
    const { appId } = await Database.getAppConfig();
    const { deviceId } = await Database.getSubscription();
    if (!deviceId || !deviceId.value)
      throw new NotSubscribedError(NotSubscribedReason.NoDeviceId);
    const result = await OneSignalApi.updatePlayer(appId, deviceId, {
      em_m: Crypto.md5(sanitizedEmail),
      em_s: Crypto.sha1(sanitizedEmail),
      em_s256: Crypto.sha256(sanitizedEmail)
    });
    if (result && result.success) {
      return true;
    } else {
      throw result;
    }
  }

  /**
   * Returns true if the current browser supports web push.
   * @PublicApi
   */
  static isPushNotificationsSupported() {
    logMethodCall('isPushNotificationsSupported');
    /*
      Push notification support is checked in the initial entry code. If in an unsupported environment, a stubbed empty
      version of the SDK will be loaded instead. This file will only be loaded if push notifications are supported.
     */
    return true;
  }

  /**
   * Initializes the SDK, called by the developer.
   * @PublicApi
   */
  static async init(options) {
    logMethodCall('init');

    InitHelper.ponyfillSafariFetch();
    InitHelper.errorIfInitAlreadyCalled();

    const appConfig = await InitHelper.downloadAndMergeAppConfig(options);
    log.debug(`OneSignal: Final web app config: %c${JSON.stringify(appConfig, null, 4)}`, getConsoleStyle('code'));
    OneSignal.context = new Context(appConfig);
    OneSignal.config = OneSignal.context.appConfig;
    OneSignal.context.workerMessenger.listen();

    if (Browser.safari && !OneSignal.config.safariWebId) {
      /**
       * Don't throw an error for missing Safari config; many users set up
       * support on Chrome/Firefox and don't intend to support Safari but don't
       * place conditional initialization checks.
       */
      log.warn(new SdkInitError(SdkInitErrorKind.MissingSafariWebId));
      return;
    }

    async function __init() {
      if (OneSignal.__initAlreadyCalled) {
        return;
      } else {
        OneSignal.__initAlreadyCalled = true;
      }

      MainHelper.fixWordpressManifestIfMisplaced();

      OneSignal.on(OneSignal.EVENTS.NATIVE_PROMPT_PERMISSIONCHANGED, EventHelper.onNotificationPermissionChange);
      OneSignal.on(OneSignal.EVENTS.SUBSCRIPTION_CHANGED, EventHelper._onSubscriptionChanged);
      OneSignal.on(OneSignal.EVENTS.SDK_INITIALIZED, InitHelper.onSdkInitialized);

      if (SubscriptionHelper.isUsingSubscriptionWorkaround()) {
        OneSignal.appConfig = appConfig;

        /**
         * The user may have forgot to choose a subdomain in his web app setup.
         *
         * Or, the user may have an HTTP & HTTPS site while using an HTTPS-only
         * config on both variants. This would cause the HTTPS site to work
         * perfectly, while causing errors and preventing web push from working
         * on the HTTP site.
         */
        if (!appConfig.subdomain) {
          throw new SdkInitError(SdkInitErrorKind.MissingSubdomain);
        }
        /**
         * The iFrame may never load (e.g. OneSignal might be down), in which
         * case the rest of the SDK's initialization will be blocked. This is a
         * good thing! We don't want to access IndexedDb before we know which
         * origin to store data on.
         */
        OneSignal.proxyFrameHost = await AltOriginManager.discoverAltOrigin(appConfig);
      }

      window.addEventListener('focus', () => {
        // Checks if permission changed everytime a user focuses on the page, since a user has to click out of and back on the page to check permissions
        MainHelper.checkAndTriggerNotificationPermissionChanged();
      });

      InitHelper.initSaveState(document.title)
        .then(() => InitHelper.saveInitOptions())
        .then(() => {
          if (SdkEnvironment.getWindowEnv() === WindowEnvironmentKind.CustomIframe) {
            Event.trigger(OneSignal.EVENTS.SDK_INITIALIZED);
          } else {
            InitHelper.internalInit();
          }
        });
    }

    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      __init();
    }
    else {
      log.debug('OneSignal: Waiting for DOMContentLoaded or readyStateChange event before continuing' +
        ' initialization...');
      window.addEventListener('DOMContentLoaded', () => {
        __init();
      });
      document.onreadystatechange = () => {
        if (document.readyState === 'complete' || document.readyState === 'interactive') {
          __init();
        }
      };
    }
  }

  /**
   * Shows a sliding modal prompt on the page for users to trigger the HTTP popup window to subscribe.
   * @PublicApi
   */
  static async showHttpPrompt(options?) {
    if (!options) {
      options = {};
    }

    await awaitOneSignalInitAndSupported();
    /*
     Only show the HTTP popover if:
     - Notifications aren't already enabled
     - The user isn't manually opted out (if the user was manually opted out, we don't want to prompt the user)
     */
    if (OneSignal.__isPopoverShowing) {
      throw new InvalidStateError(InvalidStateReason.RedundantPermissionMessage, {
        permissionPromptType: PermissionPromptType.SlidedownPermissionMessage
      });
    }

    const permission = await OneSignal.getNotificationPermission();
    const isEnabled = await OneSignal.isPushNotificationsEnabled();
    const notOptedOut = await OneSignal.getSubscription();
    const doNotPrompt = await MainHelper.wasHttpsNativePromptDismissed();

    if (doNotPrompt && !options.force) {
      throw new PermissionMessageDismissedError();
    }
    if (permission === NotificationPermission.Denied) {
      throw new PushPermissionNotGrantedError(PushPermissionNotGrantedErrorReason.Blocked);
    }
    if (isEnabled) {
      throw new AlreadySubscribedError();
    }
    if (!notOptedOut) {
      throw new NotSubscribedError(NotSubscribedReason.OptedOut);
    }
    if (MainHelper.isUsingHttpPermissionRequest()) {
      if (options.__sdkCall && options.__useHttpPermissionRequestStyle) {
      } else {
        log.debug('The slidedown permission message cannot be used while the HTTP perm. req. is enabled.');
        throw new InvalidStateError(InvalidStateReason.RedundantPermissionMessage, {
          permissionPromptType: PermissionPromptType.HttpPermissionRequest
        });
      }
    }

    MainHelper.markHttpPopoverShown();

    const sdkStylesLoadResult = await OneSignal.context.dynamicResourceLoader.loadSdkStylesheet();
    if (sdkStylesLoadResult !== ResourceLoadState.Loaded) {
      log.debug('Not showing slidedown permission message because styles failed to load.');
      return;
    }
    OneSignal.popover = new Popover(OneSignal.config.userConfig.promptOptions);
    OneSignal.popover.create();
    log.debug('Showing the HTTP popover.');
    if (OneSignal.notifyButton &&
      OneSignal.notifyButton.options.enable &&
      OneSignal.notifyButton.launcher.state !== 'hidden') {
      OneSignal.notifyButton.launcher.waitUntilShown()
        .then(() => {
          OneSignal.notifyButton.launcher.hide();
        });
    }
    OneSignal.once(Popover.EVENTS.SHOWN, () => {
      OneSignal.__isPopoverShowing = true;
    });
    OneSignal.once(Popover.EVENTS.CLOSED, () => {
      OneSignal.__isPopoverShowing = false;
      if (OneSignal.notifyButton &&
        OneSignal.notifyButton.options.enable) {
        OneSignal.notifyButton.launcher.show();
      }
    });
    OneSignal.once(Popover.EVENTS.ALLOW_CLICK, () => {
      OneSignal.popover.close();
      log.debug("Setting flag to not show the popover to the user again.");
      TestHelper.markHttpsNativePromptDismissed();
      if (options.__sdkCall && options.__useHttpPermissionRequestStyle) {
        OneSignal.registerForPushNotifications({ httpPermissionRequest: true });
      } else {
        OneSignal.registerForPushNotifications({ autoAccept: true });
      }
    });
    OneSignal.once(Popover.EVENTS.CANCEL_CLICK, () => {
      log.debug("Setting flag to not show the popover to the user again.");
      TestHelper.markHttpsNativePromptDismissed();
    });
  }

  /**
   * Prompts the user to subscribe.
   * @PublicApi
   */
  static registerForPushNotifications(options?: any) {
    // WARNING: Do NOT add callbacks that have to fire to get from here to window.open in _sessionInit.
    //          Otherwise the pop-up to ask for push permission on HTTP connections will be blocked by Chrome.
    function __registerForPushNotifications() {
      if (SubscriptionHelper.isUsingSubscriptionWorkaround()) {
          /**
           * Users may be subscribed to either .onesignal.com or .os.tc. By this time
           * that they are subscribing to the popup, the Proxy Frame has already been
           * loaded and the user's subscription status has been obtained. We can then
           * use the Proxy Frame present now and check its URL to see whether the user
           * is finally subscribed to .onesignal.com or .os.tc.
           */
        OneSignal.subscriptionPopupHost = new SubscriptionPopupHost(OneSignal.proxyFrameHost.url, options);
        OneSignal.subscriptionPopupHost.load();
      } else {
        if (!options)
          options = {};
        options.fromRegisterFor = true;
        InitHelper.sessionInit(options);
      }
    }

    if (!OneSignal.initialized) {
      OneSignal.once(OneSignal.EVENTS.SDK_INITIALIZED, () => __registerForPushNotifications());
    } else {
      return __registerForPushNotifications();
    }
  }

  /**
   * Prompts the user to subscribe using the remote local notification workaround for HTTP sites.
   * @PublicApi
   */
  static async showHttpPermissionRequest(options?: {_sdkCall: boolean}): Promise<any> {
    log.debug('Called showHttpPermissionRequest().');

    await awaitOneSignalInitAndSupported();

    // Safari's push notifications are one-click Allow and shouldn't support this workaround
    if (Browser.safari) {
      throw new InvalidStateError(InvalidStateReason.UnsupportedEnvironment);
    }

    if (OneSignal.__isPopoverShowing) {
      throw new InvalidStateError(InvalidStateReason.RedundantPermissionMessage, {
        permissionPromptType: PermissionPromptType.SlidedownPermissionMessage
      });
    }

    if (OneSignal._showingHttpPermissionRequest) {
      throw new InvalidStateError(InvalidStateReason.RedundantPermissionMessage, {
        permissionPromptType: PermissionPromptType.HttpPermissionRequest
      });
    }

    if (SubscriptionHelper.isUsingSubscriptionWorkaround()) {
      return await new Promise<NotificationPermission>((resolve, reject) => {
        OneSignal.proxyFrameHost.message(OneSignal.POSTMAM_COMMANDS.SHOW_HTTP_PERMISSION_REQUEST, options, reply => {
          let { status, result } = reply.data;
          if (status === 'resolve') {
            resolve(<NotificationPermission>result);
          } else {
            reject(result);
          }
        });
      });
    } else {
      if (!MainHelper.isUsingHttpPermissionRequest()) {
        log.debug('Not showing HTTP permission request because its not enabled. Check init option httpPermissionRequest.');
        return;
      }

      // Default call by our SDK, not forced by user, don't show if the HTTP perm. req. was dismissed by user
      if (MainHelper.wasHttpsNativePromptDismissed()) {
        if (options._sdkCall === true) {
          // TODO: Throw an error; Postmam currently does not serialize errors across cross-domain messaging
          //       In the future, Postmam should serialize errors so we can throw a PermissionMessageDismissedError
          log.debug('The HTTP perm. req. permission was dismissed, so we are not showing the request.');
          return;
        } else {
          log.debug('The HTTP perm. req. was previously dismissed, but this call was made explicitly.');
        }
      }


      const notificationPermission = await OneSignal.getNotificationPermission();

      if (notificationPermission === NotificationPermission.Default) {
        log.debug(`(${SdkEnvironment.getWindowEnv().toString()}) Showing HTTP permission request.`);
        OneSignal._showingHttpPermissionRequest = true;
        return await new Promise(resolve => {
          window.Notification.requestPermission(permission => {
            OneSignal._showingHttpPermissionRequest = false;
            resolve(permission);
            log.debug('HTTP Permission Request Result:', permission);
            if (permission === 'default') {
              TestHelper.markHttpsNativePromptDismissed();
              (OneSignal.proxyFrame as any).message(OneSignal.POSTMAM_COMMANDS.REMOTE_NOTIFICATION_PERMISSION_CHANGED, {
                permission: permission,
                forceUpdatePermission: true
              });
            }
          });
          Event.trigger(OneSignal.EVENTS.PERMISSION_PROMPT_DISPLAYED);
        });
      } else if (notificationPermission === NotificationPermission.Granted &&
        !(await OneSignal.isPushNotificationsEnabled())) {
        // User unsubscribed but permission granted. Reprompt the user for push on the host page
        (OneSignal.proxyFrame as any).message(OneSignal.POSTMAM_COMMANDS.HTTP_PERMISSION_REQUEST_RESUBSCRIBE);
      }
    }
  }

  /**
   * Returns a promise that resolves to the browser's current notification permission as 'default', 'granted', or 'denied'.
   * @param callback A callback function that will be called when the browser's current notification permission has been obtained, with one of 'default', 'granted', or 'denied'.
   * @PublicApi
   */
  static getNotificationPermission(onComplete?): Promise<NotificationPermission> {
    return awaitOneSignalInitAndSupported()
      .then(() => {
        let safariWebId = null;
        if (OneSignal.config) {
          safariWebId = OneSignal.config.safariWebId;
        }
        return MainHelper.getNotificationPermission(safariWebId);
      })
      .then((permission: NotificationPermission) => {
        if (onComplete) {
          onComplete(permission);
        }
        return permission;
      });
  }

  /**
   * @PublicApi
   */
  static async getTags(callback) {
    await awaitOneSignalInitAndSupported();
    logMethodCall('getTags', callback);
    const { appId } = await Database.getAppConfig();
    const { deviceId } = await Database.getSubscription();
    if (!deviceId || !deviceId.value) {
      // TODO: Throw an error here in future v2; for now it may break existing client implementations.
      log.info(new NotSubscribedError(NotSubscribedReason.NoDeviceId));
      return null;
    }
    const { tags } = await OneSignalApi.getPlayer(appId, deviceId);
    executeCallback(callback, tags);
    return tags;
  }

  /**
   * @PublicApi
   */
  static async sendTag(key: string, value: any, callback?: Action<Object>): Promise<Object> {
    const tag = {};
    tag[key] = value;
    return await OneSignal.sendTags(tag, callback);
  }

  /**
   * @PublicApi
   */
  static async sendTags(tags: Object, callback?: Action<Object>): Promise<Object> {
    await awaitOneSignalInitAndSupported();
    logMethodCall('sendTags', tags, callback);
    if (!tags || Object.keys(tags).length === 0) {
      // TODO: Throw an error here in future v2; for now it may break existing client implementations.
      log.info(new InvalidArgumentError('tags', InvalidArgumentReason.Empty));
      return null;
    }
    // Our backend considers false as removing a tag, so convert false -> "false" to allow storing as a value
    Object.keys(tags).forEach(key => {
      if (tags[key] === false)
        tags[key] = "false";
    });
    const { appId } = await Database.getAppConfig();
    var { deviceId } = await Database.getSubscription();
    if (!deviceId || !deviceId.value) {
      await awaitSdkEvent(OneSignal.EVENTS.REGISTERED);
    }
    // After the user subscribers, he will have a device ID, so get it again
    var { deviceId: newDeviceId } = await Database.getSubscription();
    await OneSignalApi.updatePlayer(appId, newDeviceId, {
      tags: tags
    });
    executeCallback(callback, tags);
    return tags;
  }

  /**
   * @PublicApi
   */
  static async deleteTag(tag: string): Promise<Array<string>> {
    return await OneSignal.deleteTags([tag]);
  }

  /**
   * @PublicApi
   */
  static async deleteTags(tags: Array<string>, callback?: Action<Array<string>>): Promise<Array<string>> {
    await awaitOneSignalInitAndSupported();
    logMethodCall('deleteTags', tags, callback);
    if (!ValidatorUtils.isValidArray(tags))
      throw new InvalidArgumentError('tags', InvalidArgumentReason.Malformed);
    if (tags.length === 0) {
      // TODO: Throw an error here in future v2; for now it may break existing client implementations.
      log.info(new InvalidArgumentError('tags', InvalidArgumentReason.Empty));
    }
    const tagsToSend = {};
    for (let tag of tags) {
      tagsToSend[tag] = '';
    }
    const deletedTags = await OneSignal.sendTags(tagsToSend);
    const deletedTagKeys = Object.keys(deletedTags);
    executeCallback(callback, deletedTagKeys);
    return deletedTagKeys;
  }

  /**
   * @PublicApi
   */
  static async addListenerForNotificationOpened(callback?: Action<Notification>) {
    await awaitOneSignalInitAndSupported();
    logMethodCall('addListenerForNotificationOpened', callback);
    OneSignal.once(OneSignal.EVENTS.NOTIFICATION_CLICKED, notification => {
      executeCallback(callback, notification);
    });
    EventHelper.fireStoredNotificationClicks(OneSignal.config.pageUrl || OneSignal.config.userConfig.pageUrl);
  }
  /**
   * @PublicApi
   * @Deprecated
   */
  static async getIdsAvailable(callback?: Action<{userId: string, registrationId: string}>):
    Promise<{userId: string, registrationId: string}> {
    await awaitOneSignalInitAndSupported();
    logMethodCall('getIdsAvailable', callback);
    const { deviceId, subscriptionToken } = await Database.getSubscription();
    const bundle = {
      userId: deviceId.value,
      registrationId: subscriptionToken
    };
    executeCallback(callback, bundle);
    return bundle;
  }

  /**
   * Returns a promise that resolves to true if all required conditions for push messaging are met; otherwise resolves to false.
   * @param callback A callback function that will be called when the current subscription status has been obtained.
   * @PublicApi
   */
  static async isPushNotificationsEnabled(callback?: Action<boolean>): Promise<boolean> {
    await awaitOneSignalInitAndSupported();
    logMethodCall('isPushNotificationsEnabled', callback);

    const hasInsecureParentOrigin = await SubscriptionHelper.hasInsecureParentOrigin();
    const { deviceId, subscriptionToken, optedOut } = await Database.getSubscription();
    const notificationPermission = await OneSignal.getNotificationPermission();

    let isPushEnabled = false;

    if (Environment.supportsServiceWorkers() &&
        !SubscriptionHelper.isUsingSubscriptionWorkaround() &&
        SdkEnvironment.getWindowEnv() !== WindowEnvironmentKind.OneSignalProxyFrame &&
        !hasInsecureParentOrigin) {

      const serviceWorkerActiveState = await OneSignal.context.serviceWorkerManager.getActiveState();
      const serviceWorkerActive = (serviceWorkerActiveState === ServiceWorkerActiveState.WorkerA) ||
        (serviceWorkerActiveState === ServiceWorkerActiveState.WorkerB);

      isPushEnabled = !!(deviceId &&
                      subscriptionToken &&
                      notificationPermission === NotificationPermission.Granted &&
                      !optedOut &&
                      serviceWorkerActive)

      const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration();
      if (serviceWorkerRegistration) {
        const actualSubscription = await serviceWorkerRegistration.pushManager.getSubscription();
        /**
         * Check the actual subscription, and not just the stored cached subscription, to ensure the user is still really subscribed.
         * Users typically test resubscribing by clearing their site permissions, which doesn't remove the stored subscription token
         * in IndexedDb we get from Google. Clearing their site permission will unsubscribe them though, so this method should reflect
         * that by checking the real state.
         */
        if (!actualSubscription) {
          isPushEnabled = false;
        }
      }
    } else {
      isPushEnabled = !!(deviceId &&
                         subscriptionToken &&
                         notificationPermission === NotificationPermission.Granted &&
                         !optedOut)
    }

    executeCallback(callback, isPushEnabled);
    return isPushEnabled;
  }

  /**
   * @PublicApi
   */
  static async setSubscription(newSubscription: boolean): Promise<void> {
    await awaitOneSignalInitAndSupported();
    logMethodCall('setSubscription', newSubscription);
    const appConfig = await Database.getAppConfig();
    const { appId } = appConfig;
    const subscription = await Database.getSubscription();
    const { deviceId } = subscription;
    if (!appConfig.appId)
      throw new InvalidStateError(InvalidStateReason.MissingAppId);
    if (!ValidatorUtils.isValidBoolean(newSubscription))
      throw new InvalidArgumentError('newSubscription', InvalidArgumentReason.Malformed);
    if (!deviceId || !deviceId.value) {
      // TODO: Throw an error here in future v2; for now it may break existing client implementations.
      log.info(new NotSubscribedError(NotSubscribedReason.NoDeviceId));
      return;
    }
    subscription.optedOut = !newSubscription;
    await OneSignalApi.updatePlayer(appId, deviceId, {
      notification_types: MainHelper.getNotificationTypeFromOptIn(newSubscription)
    });
    await Database.setSubscription(subscription);
    EventHelper.onInternalSubscriptionSet(subscription.optedOut);
    EventHelper.checkAndTriggerSubscriptionChanged();
  }

  /**
   * @PendingPublicApi
   */
  static async isOptedOut(callback?: Action<boolean>): Promise<boolean> {
    await awaitOneSignalInitAndSupported();
    logMethodCall('isOptedOut', callback);
    const { optedOut } = await Database.getSubscription();
    executeCallback(callback, optedOut);
    return optedOut;
  }

  /**
   * Returns a promise that resolves once the manual subscription override has been set.
   * @private
   * @PendingPublicApi
   */
  static async optOut(doOptOut: boolean, callback?: Action<void>): Promise<void> {
    await awaitOneSignalInitAndSupported();
    logMethodCall('optOut', doOptOut, callback);
    if (!ValidatorUtils.isValidBoolean(doOptOut))
      throw new InvalidArgumentError('doOptOut', InvalidArgumentReason.Malformed);
    await OneSignal.setSubscription(!doOptOut);
    executeCallback(callback);
  }

  /**
   * Returns a promise that resolves to the stored OneSignal user ID if one is set; otherwise null.
   * @param callback A function accepting one parameter for the OneSignal user ID.
   * @PublicApi
   */
  static async getUserId(callback?: Action<string>): Promise<string> {
    await awaitOneSignalInitAndSupported();
    logMethodCall('getUserId', callback);
    const subscription = await Database.getSubscription();
    const deviceId = subscription.deviceId;
    executeCallback(callback, deviceId.value);
    return deviceId.value;
  }

  /**
   * Returns a promise that resolves to the stored push token if one is set; otherwise null.
   * @PublicApi
   */
  static async getRegistrationId(callback?: Action<string>): Promise<string> {
    await awaitOneSignalInitAndSupported();
    logMethodCall('getRegistrationId', callback);
    const subscription = await Database.getSubscription();
    const subscriptionToken = subscription.subscriptionToken;
    executeCallback(callback, subscriptionToken);
    return subscriptionToken;
  }

  /**
   * Returns a promise that resolves to false if setSubscription(false) is "in effect". Otherwise returns true.
   * This means a return value of true does not mean the user is subscribed, only that the user did not call
   * setSubcription(false).
   * @private
   * @PublicApi (given to customers)
   */
  static async getSubscription(callback?: Action<boolean>): Promise<boolean> {
    await awaitOneSignalInitAndSupported();
    logMethodCall('getSubscription', callback);
    const subscription = await Database.getSubscription();
    const subscriptionStatus = !subscription.optedOut;
    executeCallback(callback, subscriptionStatus);
    return subscriptionStatus;
  }

  /**
   * @PublicApi
   */
  static async sendSelfNotification(title: string = 'OneSignal Test Message',
                              message: string = 'This is an example notification.',
                              url: string = new URL(location.href).origin + '?_osp=do_not_open',
                              icon: URL,
                              data: Map<String, any>,
                              buttons: Array<NotificationActionButton>): Promise<void> {
    await awaitOneSignalInitAndSupported();
    logMethodCall('sendSelfNotification', title, message, url, icon, data, buttons);
    const appConfig = await Database.getAppConfig();
    const subscription = await Database.getSubscription();
    if (!appConfig.appId)
      throw new InvalidStateError(InvalidStateReason.MissingAppId);
    if (!subscription.deviceId)
      throw new NotSubscribedError(NotSubscribedReason.NoDeviceId);
    if (!ValidatorUtils.isValidUrl(url))
      throw new InvalidArgumentError('url', InvalidArgumentReason.Malformed);
    if (!ValidatorUtils.isValidUrl(icon, { allowEmpty: true, requireHttps: true }))
      throw new InvalidArgumentError('icon', InvalidArgumentReason.Malformed);
    return await OneSignalApi.sendNotification(appConfig.appId, [subscription.deviceId], {'en': title}, {'en': message},
                                               url, icon, data, buttons);
  }

  /**
   * Used to load OneSignal asynchronously from a webpage.
   * @InternalApi
   */
  static push(item) {
    if (typeof(item) == "function")
      item();
    else {
      var functionName = item.shift();
      OneSignal[functionName].apply(null, item);
    }
  }

  static __doNotShowWelcomeNotification: boolean;
  static VERSION = Environment.version();
  static _VERSION = Environment.version();
  static sdkEnvironment = SdkEnvironment;
  static _notificationOpenedCallbacks = [];
  static _idsAvailable_callback = [];
  static _defaultLaunchURL = null;
  static config = null;
  static __isPopoverShowing = false;
  static _sessionInitAlreadyRunning = false;
  static _isNotificationEnabledCallback = [];
  static _subscriptionSet = true;
  static modalUrl = null;
  static _windowWidth = 650;
  static _windowHeight = 568;
  static _isNewVisitor = false;
  static _channel = null;
  static cookie = Cookie;
  static initialized = false;
  static notifyButton = null;
  static store = LimitStore;
  static environment = Environment;
  static database = Database;
  static event = Event;
  static browser = Browser;
  static popover = null;
  static log = log;
  static api = OneSignalApi;
  static indexedDb = IndexedDb;
  static mainHelper = MainHelper;
  static subscriptionHelper = SubscriptionHelper;
  static httpHelper =  HttpHelper;
  static eventHelper = EventHelper;
  static initHelper = InitHelper;
  static testHelper = TestHelper;
  static objectAssign = objectAssign;
  static appConfig = null;
  static subscriptionPopup: SubscriptionPopup;
  static subscriptionPopupHost: SubscriptionPopupHost;
  static subscriptionModal: SubscriptionModal;
  static subscriptionModalHost: SubscriptionModalHost;
  static proxyFrameHost: ProxyFrameHost;
  static proxyFrame: ProxyFrame;

  /**
   * The additional path to the worker file.
   *
   * Usually just the filename (in case the file is named differently), but also supports cases where the folder
   * is different.
   *
   * However, the init options 'path' should be used to specify the folder path instead since service workers will not
   * auto-update correctly on HTTPS site load if the config init options 'path' is not set.
   */
  static SERVICE_WORKER_UPDATER_PATH = 'OneSignalSDKUpdaterWorker.js';
  static SERVICE_WORKER_PATH = 'OneSignalSDKWorker.js';

  /**
   * By default, the service worker is expected to be accessible at the root scope. If the service worker is only
   * available with in a sub-directory, SERVICE_WORKER_PARAM must be changed to the sub-directory (with a trailing
   * slash). This would allow pages to function correctly as not to block the service worker ready call, which would
   * hang indefinitely if we requested root scope registration but the service was only available in a child scope.
   */
  static SERVICE_WORKER_PARAM: { scope: string } = {scope: '/'};
  static _LOGGING = false;
  static LOGGING = false;
  static _usingNativePermissionHook = false;
  static _initCalled = false;
  static __initAlreadyCalled = false;
  static httpPermissionRequestPostModal: any;
  static _showingHttpPermissionRequest = false;
  static context: Context;
  static checkAndWipeUserSubscription = function () { }
  static crypto = Crypto;
  static PushRegistration = PushRegistration;

  static notificationPermission = NotificationPermission;


  /**
   * Used by Rails-side HTTP popup. Must keep the same name.
   * @InternalApi
   */
  static _initHttp = HttpHelper.initHttp;

  /**
   * Used by Rails-side HTTP popup. Must keep the same name.
   * @InternalApi
   */
  static _initPopup = () => OneSignal.subscriptionPopup.subscribe();

  static POSTMAM_COMMANDS = {
    CONNECTED: 'connect',
    REMOTE_NOTIFICATION_PERMISSION: 'postmam.remoteNotificationPermission',
    REMOTE_DATABASE_GET: 'postmam.remoteDatabaseGet',
    REMOTE_DATABASE_PUT: 'postmam.remoteDatabasePut',
    REMOTE_DATABASE_REMOVE: 'postmam.remoteDatabaseRemove',
    REMOTE_OPERATION_COMPLETE: 'postman.operationComplete',
    REMOTE_RETRIGGER_EVENT: 'postmam.remoteRetriggerEvent',
    MODAL_LOADED: 'postmam.modalPrompt.loaded',
    MODAL_PROMPT_ACCEPTED: 'postmam.modalPrompt.accepted',
    MODAL_PROMPT_REJECTED: 'postmam.modalPrompt.canceled',
    POPUP_LOADED: 'postmam.popup.loaded',
    POPUP_ACCEPTED: 'postmam.popup.accepted',
    POPUP_REJECTED: 'postmam.popup.canceled',
    POPUP_CLOSING: 'postman.popup.closing',
    REMOTE_NOTIFICATION_PERMISSION_CHANGED: 'postmam.remoteNotificationPermissionChanged',
    IFRAME_POPUP_INITIALIZE: 'postmam.iframePopupInitialize',
    UNSUBSCRIBE_FROM_PUSH: 'postmam.unsubscribeFromPush',
    SET_SESSION_COUNT: 'postmam.setSessionCount',
    REQUEST_HOST_URL: 'postmam.requestHostUrl',
    SHOW_HTTP_PERMISSION_REQUEST: 'postmam.showHttpPermissionRequest',
    IS_SHOWING_HTTP_PERMISSION_REQUEST: 'postmam.isShowingHttpPermissionRequest',
    WINDOW_TIMEOUT: 'postmam.windowTimeout',
    FINISH_REMOTE_REGISTRATION: 'postmam.finishRemoteRegistration',
    FINISH_REMOTE_REGISTRATION_IN_PROGRESS: 'postmam.finishRemoteRegistrationInProgress',
    POPUP_BEGIN_MESSAGEPORT_COMMS: 'postmam.beginMessagePortComms',
    SERVICEWORKER_COMMAND_REDIRECT: 'postmam.command.redirect',
    HTTP_PERMISSION_REQUEST_RESUBSCRIBE: 'postmam.httpPermissionRequestResubscribe',
    MARK_PROMPT_DISMISSED: 'postmam.markPromptDismissed',
    IS_SUBSCRIBED: 'postmam.isSubscribed',
    UNSUBSCRIBE_PROXY_FRAME: 'postman.unsubscribeProxyFrame',
    GET_EVENT_LISTENER_COUNT: 'postmam.getEventListenerCount'
  };

  static EVENTS = {
    /**
     * Occurs when the user clicks the "Continue" or "No Thanks" button on the HTTP popup or HTTPS modal prompt.
     * For HTTP sites (and HTTPS sites using the modal prompt), this event is fired before the native permission
     * prompt is shown. This event is mostly used for HTTP sites.
     */
    CUSTOM_PROMPT_CLICKED: 'customPromptClick',
    /**
     * Occurs when the user clicks "Allow" or "Block" on the native permission prompt on Chrome, Firefox, or Safari.
     * This event is used for both HTTP and HTTPS sites and occurs after the user actually grants notification
     * permissions for the site. Occurs before the user is actually subscribed to push notifications.
     */
    NATIVE_PROMPT_PERMISSIONCHANGED: 'notificationPermissionChange',
    /**
     * Occurs after the user is officially subscribed to push notifications. The service worker is fully registered
     * and activated and the user is eligible to receive push notifications at any point after this.
     */
    SUBSCRIPTION_CHANGED: 'subscriptionChange',
    /**
     * Occurs after a POST call to OneSignal's server to send the welcome notification has completed. The actual
     * notification arrives shortly after.
     */
    WELCOME_NOTIFICATION_SENT: 'sendWelcomeNotification',
    /**
     * Occurs when a notification is displayed.
     */
    NOTIFICATION_DISPLAYED: 'notificationDisplay',
    /**
     * Occurs when a notification is dismissed by the user either clicking 'X' or clearing all notifications
     * (available in Android). This event is NOT called if the user clicks the notification's body or any of the
     * action buttons.
     */
    NOTIFICATION_DISMISSED: 'notificationDismiss',
    /**
     * New event replacing legacy addNotificationOpenedHandler(). Used when the notification was clicked.
     */
    NOTIFICATION_CLICKED: 'notificationClick',
    /**
     * Occurs after the document ready event fires and, for HTTP sites, the iFrame to subdomain.onesignal.com has
     * loaded.
     * Before this event, IndexedDB access is not possible for HTTP sites.
     */
    SDK_INITIALIZED: 'initialize',
    /**
     * Occurs after the user subscribes to push notifications and a new user entry is created on OneSignal's server,
     * and also occurs when the user begins a new site session and the last_session and last_active is updated on
     * OneSignal's server.
     */
    REGISTERED: 'register',
    /**
     * Occurs as the HTTP popup is closing.
     */
    POPUP_CLOSING: 'popupClose',
    /**
     * Occurs when the native permission prompt is displayed.
     */
    PERMISSION_PROMPT_DISPLAYED: 'permissionPromptDisplay',
    /**
     * For internal testing only. Used for all sorts of things.
     */
    TEST_INIT_OPTION_DISABLED: 'testInitOptionDisabled',
    TEST_WOULD_DISPLAY: 'testWouldDisplay',
    POPUP_WINDOW_TIMEOUT: 'popupWindowTimeout',
  };

  static NOTIFICATION_TYPES = {
    SUBSCRIBED: 1,
    UNSUBSCRIBED: -2
  };

  /** To appease TypeScript, EventEmitter later overrides this */
  static on(..._) {}
  static off(..._) {}
  static once(..._) {}
}

Object.defineProperty(OneSignal, 'LOGGING', {
  get: function() {
    return OneSignal._LOGGING;
  },
  set: function(logLevel) {
    if (logLevel) {
      log.setDefaultLevel((<any>log).levels.TRACE);
      OneSignal._LOGGING = true;
    }
    else {
      log.setDefaultLevel((<any>log).levels.WARN);
      OneSignal._LOGGING = false;
    }
  },
  enumerable: true,
  configurable: true
});

heir.merge(OneSignal, new EventEmitter());


if (OneSignal.LOGGING)
  log.setDefaultLevel((<any>log).levels.TRACE);
else {
  log.setDefaultLevel((<any>log).levels.WARN);
}

LegacyManager.ensureBackwardsCompatibility(OneSignal);

log.info(`%cOneSignal Web SDK loaded (version ${OneSignal._VERSION}, ${SdkEnvironment.getWindowEnv().toString()} environment).`, getConsoleStyle('bold'));
log.debug(`Current Page URL: ${typeof location === "undefined" ? "NodeJS" : location.href}`);
log.debug(`Browser Environment: ${Browser.name} ${Browser.version}`);
